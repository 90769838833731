document.addEventListener('turbolinks:load', function () {
    if ($('.organizations.new, .organizations.edit').length === 0) {
        return false
    }

    $(document).on('change', '#organization_permit_fees_share', function (e) {
        let clientShare = $(this).val()
        let aokaShare = 100 - clientShare
        $('.aoka-share').text(`${aokaShare}%`)
    })
});