document.addEventListener('DOMContentLoaded', function() {
    const textArea = document.querySelector('#inspectionNote');
    let timer;

    if(!textArea) return;

    textArea.addEventListener('input', function() {
        clearTimeout(timer);
        timer = setTimeout(autoSave, 1000);
    });

    function autoSave() {
        const form = document.getElementById('inspectionNoteForm');

        $.ajax({
            type: "PUT",
            url: form.action,
            data: {
                inspection: { note: document.querySelector('#inspectionNote').value },
                authenticity_token: $("meta[name='csrf-token']").attr('content')
            },
            method: 'put'
        }).done(function (data) {
            console.log(data);
        }).fail(function (data) {
            console.log(data);
        });
    }
});