document.addEventListener('turbolinks:load', function () {
    if ($('.permit_fee_structures.new, .permit_fee_structures.edit, .permit_fee_structures.create, .permit_fee_structures.update').length === 0) {
        return false
    }

    // only show permit section when all of the fields from key section is selected
    $(document).off('change', '.key-section select').on('change', '.key-section select', function () {
        let permit_section = $('.permit-section')
        let intended_use_of_parcel = $('.key-section select.intended_use_of_parcel').val()
        let type_of_construction = $('.key-section select.type_of_construction').val()
        let project_type = $('.key-section select.project_type').val()

        if (intended_use_of_parcel.length > 0 && type_of_construction.length > 0 && project_type.length > 0) {
            if (permit_section.hasClass('d-none')) {
                permit_section.removeClass('d-none')
            }
        } else {
            if (!permit_section.hasClass('d-none')) {
                permit_section.addClass('d-none')
            }
        }
    })
    // only show permit section when all of the fields from key section is selected


    // only show fee section when one of the permits is selected
    $(document).off('change', '.permit-checkbox input:checkbox').on('change', '.permit-checkbox input:checkbox', function () {
        let fee_section = $('.fee-section')
        if ($('.permit-checkbox input:checkbox:checked').length > 0) {
            if (fee_section.hasClass('d-none')) {
                fee_section.removeClass('d-none')
            }
        } else {
            if (!fee_section.hasClass('d-none')) {
                fee_section.addClass('d-none')
            }
        }
    })
    // only show fee section when one of the permits is selected


    // for changes when selecting fee type starts here
    $(document).off('change', '.select-fee-type').on('change', '.select-fee-type', function () {
        var fee_type = $(this).val()
        var fixed_section = $(this).parent().siblings('.fixed-fee-amount-section')
        var range_section = $(this).parent().siblings('.range-fee-amount-section')

        toggleFeeType(fee_type, range_section, fixed_section)
    })
    // for changes when selecting fee type starts here


    // for changes when selecting based on starts here
    $(document).off('change', '.select-based-on').on('change', '.select-based-on', function () {
        var based_on = $(this).val()
        var fee_structure_section = $('.fee-structure-section')
        toggleFeeStructureSection(based_on, fee_structure_section)

        var range_field_indicator = $('.fee-structure-range-field-section .indicator')
        if (based_on === 'total_sq_ft') {
            range_field_indicator.text('(S.F.)')
        } else {
            range_field_indicator.text('($)')
        }
    })

    function toggleFeeStructureSection(based_on, fee_structure_section) {
        if (based_on.length > 0) {
            if (fee_structure_section.hasClass('d-none')) {
                fee_structure_section.removeClass('d-none')
            }
        } else {
            if (!fee_structure_section.hasClass('d-none')) {
                fee_structure_section.addClass('d-none')
            }
        }
    }

    // for changes when selecting based on starts here


    // for fee structures start here
    $(document).off('change', '.fee-structure-type-section .fee-structure-type').on('change', '.fee-structure-type-section .fee-structure-type', function () {
        var fee_type = $(this).val()
        var fixed_section = $(this).parent().siblings('.fee-structure-fixed-amount-section')
        var range_section = $(this).parent().siblings('.fee-structure-range-section')

        toggleFeeType(fee_type, range_section, fixed_section)
    })

    // cloning forms start here
    $(document).off('click', '.add-fee-structure').on('click', '.add-fee-structure', function (e) {
        e.preventDefault()
        let lastForm = $('.cloningForm').last()
        let lastFormId = lastForm.attr('id')
        let clone = lastForm.clone(true)

        emptyClonedForm(clone)

        $(clone).find("select").val(lastForm.find('select').val())

        // changing name attribute for cloned form field to make it easier to store data
        // on the database
        changeInAttribute(clone, lastFormId);

        showRemoveButton(clone)

        $(clone).appendTo('.wrapper')
    })

    function emptyClonedForm(clone) {
        $(clone).find("input").each((i, field) => {
            $(field).val('')
        })
    }

    function changeInAttribute(clone, lastFormId) {
        let newCount = parseInt(lastFormId) + 1;
        $(clone).find("input,select").each((i, field) => {
            field.name = field.name.replace(lastFormId, newCount);
            field.id = field.id.replace(lastFormId, newCount)
        })
        $(clone).attr('id', newCount.toString())
    }

    function showRemoveButton(clone) {
        let removeBtn = $(clone).find(".remove-icon")
        if (removeBtn.hasClass('d-none')) {
            removeBtn.removeClass('d-none')
        }
    }

    $(document).on('click', '.remove-icon', function () {
        $(this).closest('.cloningForm').remove()
    })
    // cloning forms end here
    // for fee structures end here


    // common functions
    function toggleFeeType(fee_type, range_section, fixed_section) {
        if (fee_type === 'range') {
            if (range_section.hasClass('d-none')) {
                range_section.removeClass('d-none')
            }
            if (!fixed_section.hasClass('d-none')) {
                fixed_section.addClass('d-none')
            }
        } else {
            if (fixed_section.hasClass('d-none')) {
                fixed_section.removeClass('d-none')
            }
            if (!range_section.hasClass('d-none')) {
                range_section.addClass('d-none')
            }
        }
    }

    if ($('.permit_fee_structures.create, .permit_fee_structures.update, .permit_fee_structures.edit').length > 0) {
        $('.key-section select').trigger('change')
        $('.permit-checkbox input:checkbox').trigger('change')
        $('.select-fee-type').trigger('change')
        $('.select-based-on').trigger('change')
        $('.fee-structure-type-section .fee-structure-type').trigger('change')
    }
})
