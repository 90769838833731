deletableRows = () => {
  document.querySelectorAll('[id^="delete-inspection-button-"]').forEach(function(button) {
    button.addEventListener('click', function() {
      const inspectionId = this.getAttribute('data-inspection-id');
      const authenticityToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

      showDeleteModal(inspectionId, authenticityToken);
    });
  });
}

document.addEventListener('turbolinks:load', function () {
  deletableRows();
});

// Function to inject the modal into the DOM and show it
function showDeleteModal(inspectionId, authenticityToken) {
  // Construct the modal HTML
  const modalHtml = `
    <div class="modal fade text-start" aria-hidden="true" aria-labelledby="Delete Inspection" tabindex="-1" data-backdrop="static" id="deleteInspectionModal${inspectionId}" data-keyboard="false" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete Inspection</h5>
          </div>
          <form action="/inspections/${inspectionId}" method="post" data-remote="true">
            <input type="hidden" name="_method" value="delete">
            <input type="hidden" name="authenticity_token" value="${authenticityToken}">
            <div class="modal-body">
              <p>Are you sure you want to delete this inspection? Please provide the reason for deletion.</p>
              <textarea class="form-control field" name="inspection[delete_reason]" required minlength="50"></textarea>
            </div>
            <div class="modal-footer">
              <button class="btn btn-outline" type="button" data-bs-dismiss="modal">Cancel</button>
              <button class="btn new-btn" type="submit">Delete Inspection</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  `;

  // Append the modal HTML to the body
  document.body.insertAdjacentHTML('beforeend', modalHtml);

  // Show the modal
  const modalElement = document.getElementById(`deleteInspectionModal${inspectionId}`);
  const bootstrapModal = new bootstrap.Modal(modalElement);
  bootstrapModal.show();

  // Cleanup the modal from the DOM after it's closed
  modalElement.addEventListener('hidden.bs.modal', function (event) {
    modalElement.remove();
  });
}