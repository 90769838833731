document.addEventListener('turbolinks:load', function () {
    if ($('.projects .filter-btn, .teams .filter-btn, .organizations .filter-btn, .sales_channels .filter-btn, .permit_applications .filter-btn, .contractors .filter-btn').length === 0) {
        return false
    }

    $(document).on('click', function (e) {
        var container = $(".filter-dropdown");
        var filterBtn = $('#triggerFilterDropdown')
        // when filter dropdown menu is displayed
        // dropdown menu should close when clicked on anything except filter button and dropdown menu and its children
        // the handling of closing the dropdown menu when clicked on filter button is handled somewhere else
        // and handling it here was causing issues

        if (!container.hasClass('d-none') && !container.is(e.target) && !filterBtn.is(e.target) && container.has(e.target).length === 0) {
            container.addClass('d-none');
            $('.options').addClass('d-none');
        }
    })
});