document.addEventListener('turbolinks:load', function () {
    if ($('.permit_applications.new, .permit_applications.create, .projects.edit, .permit_applications.update, .permit_applications.edit').length === 0) {
        return false
    }

    // do not want to trigger this js if we are working on project edit form
    // it should only serve the permit applications form
    if($('.permit_applications-form-section').length === 0) {
        return false
    }

    var permitInformationTab = document.querySelector('#v-permit-information-tab')
    permitInformationTab.addEventListener('shown.bs.tab', function () {
        populateProjectNameAddress();
        handlePermitSelection();
    })

    // above code is not triggered during child permit, so only calling this function instead
    if ($('.child-permit').length > 0) {
        handlePermitSelection();
    }

    function populateProjectNameAddress() {
        // during the case of child permit, name and address is already populated and construction selection tab is not present in UI so adding this condition
        if ($('#constructionSelectionTab').length > 0) {
            var intendedUseOfParcel = $("#constructionSelectionTab input:radio:checked")
            var typeOfConstruction = $("#projectTypeSelectionTab input:radio:checked")
            var projectName = [intendedUseOfParcel.parent().attr('data-project-name-value'), typeOfConstruction.parent().attr('data-project-name-value')].filter((x) => x?.length > 0).join(' ')

            var projectDetailsTab = $('#projectDetailsTab')
            var streetName = projectDetailsTab.find('#project_street').val().trim()
            var city = projectDetailsTab.find('#project_city').val().trim()
            var zipCode = projectDetailsTab.find('#project_zip_code').val()
            var address = [streetName, city, zipCode].filter((x) => x?.length > 0).join(', ')

            var projectDetailSection = $('#permitInformationTab .summary-card .project-detail-section')
            if (streetName.length > 0) {
                projectName = projectName + '- ' + streetName
            }
            projectDetailSection.find('.name').text(projectName)
            projectDetailSection.find('.address').text(address)
            $('#project_name').val(projectName)
        }
    }

    function handlePermitSelection() {
        let cardBody = $('#permitInformationTab .summary-card .body')
        let permitSection = cardBody.find('.permit-section')
        permitSection.empty()
        var projectDetailSection = $('#permitInformationTab .summary-card .project-detail-section')
        var projectName = projectDetailSection.find('.name').text()
        $(document).off('change', '.permit-checkbox.abled-permit input:checkbox').on('change', '.permit-checkbox.abled-permit input:checkbox', function () {
            var checkbox = $(this)
            var checkboxValue = checkbox.val();
            var parameterizeValue = parameterizeString(checkboxValue)
            var selectedLabelId = 'label-' + parameterizeValue
            let otherPermitInput = $('#project_other_permit')
            let otherPermitField = otherPermitInput.parent()
            let selectedPermitValue = checkboxValue

            if (checkbox.is(':checked')) {
                $('.no-permit').remove();
                let element = document.createElement('div')
                if (checkboxValue === 'Other') {
                    let otherPermit = otherPermitInput.val()
                    otherPermitField.removeClass('d-none')
                    // selectedPermitValue is used to add selected permit to project name
                    // when other is selected, we do not want Other to be added to project name
                    // so, assigning it to empty string by default such that when other permit input is filled
                    // that value is used for project name instead
                    selectedPermitValue = ''
                    if (otherPermit.length > 0) {
                        element.classList.add('selected-permit')
                        element.innerHTML = otherPermit
                        selectedPermitValue = otherPermit
                    }
                    $(document).off('change', '#project_other_permit').on('change', '#project_other_permit', function () {
                        let otherPermitValue = $(this).val()
                        element.classList.add('selected-permit')
                        element.innerHTML = otherPermitValue
                        // when other permit input is changed, we should first remove previously set other permit
                        // and then add newly set other permit to project name
                        projectName = removePermitFromProjectName(projectName, selectedPermitValue, projectDetailSection)
                        selectedPermitValue = otherPermitValue
                        projectName = addPermitToProjectName(projectName, selectedPermitValue, projectDetailSection)
                    })
                } else {
                    element.classList.add('selected-permit')
                    element.innerHTML = checkboxValue;
                }
                element.id = selectedLabelId;
                permitSection.append(element);

                // every selected permit should be appended to project name
                projectName = addPermitToProjectName(projectName, selectedPermitValue, projectDetailSection)
            } else {
                if (checkboxValue === 'Other') {
                    otherPermitField.addClass('d-none')
                    selectedPermitValue = otherPermitInput.val()
                }
                $(`#${selectedLabelId}`).remove();
                if (cardBody.find(".selected-permit").length === 0 && permitSection.find(".no-permit").length === 0) {
                    let noPermitElement = document.createElement('div')
                    noPermitElement.classList.add('no-permit')
                    noPermitElement.innerHTML = 'Please select requirement'
                    permitSection.append(noPermitElement);
                }

                // every selected permit is appended to project name so removing them when unselected
                selectedPermitValue = selectedPermitValue.trim()
                if(selectedPermitValue.length > 0) {
                    projectName = removePermitFromProjectName(projectName, selectedPermitValue, projectDetailSection)
                }
            }
        })
        $('.permit-checkbox input:checkbox:checked').trigger('change')
    }

    function addPermitToProjectName(projectName, permitValue, projectDetailSection) {
        permitValue = permitValue.trim()
        if(permitValue.length > 0 && !projectName.includes(permitValue)) {
            projectName = projectName + '- ' + permitValue
            $('#project_name').val(projectName)
            projectDetailSection.find('.name').text(projectName)
        }
        return projectName
    }

    function removePermitFromProjectName(projectName, permitValue, projectDetailSection) {
        permitValue = permitValue.trim()
        if(permitValue.length > 0) {
            projectName = projectName.replace('- ' + permitValue, '')
            $('#project_name').val(projectName)
            projectDetailSection.find('.name').text(projectName)
        }
        return projectName
    }

    function parameterizeString(string) {
        return string.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '');
    }
});