enableClickableRows = () => {
    document.querySelectorAll('.clickable-row').forEach(function (row) {
        row.querySelectorAll("td:not(.notClickable)").forEach(function (clickable) {
            if (!clickable.classList.contains('no-action-column')) {
                clickable.addEventListener('click', function () {
                    window.open(this.parentNode.getAttribute('data-href'), '_blank');
                })
            }
        })
    });
}

document.addEventListener('turbolinks:load', function () {
    enableClickableRows();
});