document.addEventListener('turbolinks:load', function () {
    var copyButton = document.getElementById('copyLinkToPage');
    var toastContainer = document.getElementById('toastContainer');

    if (!copyButton || !toastContainer) return

    copyButton.addEventListener('click', function () {
        var linkToCopy = this.getAttribute('data-link');
        navigator.clipboard.writeText(linkToCopy).then(function() {
            showToast("Link copied to clipboard!");
        }, function(err) {
            console.error('Could not copy text: ', err);
        });
    });

    function showToast(message) {
        toastContainer.textContent = message;
        toastContainer.className = "show";
        // After 3 seconds, hide the toast
        setTimeout(function(){ toastContainer.className = toastContainer.className.replace("show", ""); }, 3000);
    }
});
