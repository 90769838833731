document.addEventListener('turbolinks:load', function () {
    if ($('.hud_trip_reports.new, .hud_trip_reports.create, .hud_trip_reports.edit, .hud_trip_reports.update').length === 0) {
        return false
    }

    processStep();

    function processStep() {
        $('.previous-step-btn').on('click', function (e) {
            e.preventDefault();

            var targetId = $(this).data("target")
            submitForm(targetId)
            window.scrollTo(0, 0)
        });

        $('.next-step-btn').on('click', function (e) {
            e.preventDefault();

            var targetId = $(this).data("target")
            submitForm(targetId)
            window.scrollTo(0, 0)
        });

        $('.modal-save-btn').on('click', function (e) {
            e.preventDefault();

            var targetId = $(this).data("target")
            submitForm(targetId)
            $('html, body').animate({ scrollTop: $(document).height() }, 'slow')
        });

        $('.saveHudReport').on('click', function (e) {
            e.preventDefault();
            $('#section').val($('.tab-pane.fade.active.show').attr('id').slice(2));
            submitHudForm();
        });

        $('.submitHudReport').on('click', function (e) {
            e.preventDefault();
            $('#section').val($('.tab-pane.fade.active.show').attr('id').slice(2));
            $('#complete_report').val(true);
            submitHudForm();
        });

        $('#v-organization-form-tab .form-tab').on('click', function (e) {
            e.preventDefault();

            var targetId = $(this).data("target")
            submitForm(targetId)
            window.scrollTo(0, 0)
        });
    }

    function submitForm(targetId) {
        assignFormValue(targetId)
        submitHudForm()
    }

    function assignFormValue(targetId) {
        $('#next_tab_id').val(targetId);
        $('#section').val($('.tab-pane.fade.active.show').attr('id').slice(2));
    }

    function submitHudForm() {
        showLoader()

        let form = document.querySelector('#new-hud-form');
        let button = document.createElement('input');
        button.style.display = 'none';
        button.type = 'submit';
        form.appendChild(button).click();
        form.removeChild(button);
    }

    function showLoader() {
        $('.loading-spinner').show();
        $('body').css({
            pointerEvents: "none"
        })
    }
});