document.addEventListener('turbolinks:load', function () {
    if ($('.account_confirmation.new, .account_confirmation.create').length === 0) {
        return false
    }

    // code input section js starts
    // https://codepen.io/RobertAron/pen/gOLLXLo
    const inputElements = [...document.querySelectorAll('input.code-input')]

    inputElements.forEach((ele, index) => {
        ele.addEventListener('keydown', (e) => {
            // if the keycode is backspace & the current field is empty
            // focus the input before the current. Then the event happens
            // which will clear the "before" input box.
            if (e.keyCode === 8 && e.target.value === '') inputElements[Math.max(0, index - 1)].focus()
        })
        ele.addEventListener('input', (e) => {
            // take the first character of the input
            // this actually breaks if you input an emoji like 👨‍👩‍👧‍👦....
            // but I'm willing to overlook insane security code practices.
            const [first, ...rest] = e.target.value
            e.target.value = first ?? '' // first will be undefined when backspace was entered, so set the input to ""
            const lastInputBox = index === inputElements.length - 1
            const didInsertContent = first !== undefined
            if (didInsertContent && !lastInputBox) {
                // continue to input the rest of the string
                inputElements[index + 1].focus()
                inputElements[index + 1].value = rest.join('')
                inputElements[index + 1].dispatchEvent(new Event('input'))
            }
        })
    })
    // code input section js ends

    // send new code js starts
    var sendNewCodeLink = $('.send-new-code-link')
    var token = $("meta[name='csrf-token']").attr('content');
    var userId = $('#user_id').val()
    sendNewCodeLink.on('click', function () {
        $.ajax({
            url: "/permit/send_new_code",
            data: {
                user_id: userId,
                authenticity_token: token
            },
            method: 'POST'
        }).done(function (res) {
            if (res.error) {
                alert(res.error)
            } else {
                startCounter()
            }
        }).fail(function (res) {
            alert('Something went wrong')
        });
    })

    function startCounter() {
        var disabledLink = $('.disabled-link')
        disabledLink.removeClass('d-none')
        sendNewCodeLink.addClass('d-none')
        var counter = 59;
        var interval = setInterval(function () {
            counter--;
            // Display 'counter' wherever you want to display it.
            if (counter <= 0) {
                clearInterval(interval);
                disabledLink.addClass('d-none')
                sendNewCodeLink.removeClass('d-none')
            } else {
                $('.counter').text("(00:" + counter + ")");
                console.log("Timer --> " + counter);
            }
        }, 1000);
    }
    // send new code js starts
});