import consumer from "./consumer"

document.addEventListener('turbolinks:load', () => {
  const chatRoomElement = document.getElementById('inspection-chat-room')
  if (!chatRoomElement) {
    return
  }

  const roomId = chatRoomElement.getAttribute('data-inspection-id')

  consumer.subscriptions.create({ channel: "InspectionChatChannel", room: `inspection_chat_${roomId}` }, {
    connected() {
      console.log("Connected to the chat room " + `inspection_chat_${roomId}`)
    },

    disconnected() {
      console.log("Disconnected from the chat room " + `inspection_chat_${roomId}`)
    },

    received(data) {
      const messageContainer = document.getElementById('messages')
      const userId = document.querySelector('.call-container').getAttribute('data-user-id');

      messageContainer.innerHTML += data.message
      if (data.sender_id !== parseInt(userId)) {
        $('#chatNotification').addClass('badgeIcon');
      }
    },
  });
});
