document.addEventListener('turbolinks:load', function () {
    if ($('.plan_reviews.new, .plan_reviews.edit, .plan_reviews.create, .plan_reviews.update').length === 0) {
        return false
    }

    // adjust textarea height when comments tab is shown
    var commentsTab = document.querySelector('.comments-tab')
    commentsTab.addEventListener('shown.bs.tab', function () {
        $('.comments-textarea').each(function () {
            textAreaAdjust($(this))
        })
    })

    $('#plan-review-form textarea').each(function () {
        textAreaAdjust($(this))
    })

    $(document).on('keyup', '#plan-review-form textarea', function () {
        textAreaAdjust($(this))
    })

    // adjust textarea height
    function textAreaAdjust(element) {
        element.height('1')
        element.height(element.prop("scrollHeight") + 20)
    }

    // show dropdown on review summary section when select option is clicked
    $('.select-option').on('click', function (e) {
        e.preventDefault();
        $(this).parent().find('.select-dropdown').removeClass('d-none')
    })

    $(document).on('click', function (e) {
        var containers = $(".select-dropdown");
        // when select dropdown menu is displayed
        // dropdown menu should close when clicked on anything except select option and dropdown menu and its children

        containers.each(function () {
            var container = $(this)
            var selectOption = container.parent().find('.select-option')
            if (!container.hasClass('d-none') && !container.is(e.target) && !selectOption.is(e.target) && selectOption.has(e.target).length === 0 && container.has(e.target).length === 0) {
                container.addClass('d-none');
                $('.options').addClass('d-none');
            }
        })
    })

    // add selected checkbox and radio button to UI
    $('.select-dropdown').each(function () {
        var dropdownContainer = $(this)

        addSelectedCheckbox(dropdownContainer)
        addSelectedRadio(dropdownContainer)
    })

    function addSelectedCheckbox(dropdownContainer) {
        dropdownContainer.find('.custom-checkbox').on('change', function () {
            var checkbox = $(this)
            var checkboxValue = checkbox.val();
            var parameterizeValue = parameterizeString(checkboxValue)
            var selectedLabelId = 'label-' + parameterizeValue
            let selectedOptions = $('.selected-disciplined-reviewed')
            let selectText = selectedOptions.parent().find('.select-text')

            if (checkbox.is(':checked')) {
                let optionTemplate = selectedOptions.find('.option')[0];
                let newOption = optionTemplate.cloneNode('true');
                let selectedOptionName = checkbox.parent().find('label').html();
                $(newOption).find('.name').html(selectedOptionName);
                $(newOption).attr('id', selectedLabelId)
                selectedOptions.removeClass('d-none');
                selectedOptions.append(newOption);
                selectText.addClass('d-none')
            } else {
                $(`#${selectedLabelId}`).remove();
                if (dropdownContainer.find("input:checkbox:checked").length === 0) {
                    selectText.removeClass('d-none')
                }
            }
        })
        dropdownContainer.find('.custom-checkbox').trigger('change')
    }

    function addSelectedRadio(dropdownContainer) {
        dropdownContainer.find('.custom-radio').on('change', function () {
            var radio = $(this)
            var radioValue = radio.val();
            var parameterizeValue = parameterizeString(radioValue)
            var selectedLabelId = 'label-' + parameterizeValue
            let selectedOptions = $('.selected-plan-review-status')
            let selectText = selectedOptions.parent().find('.select-text')

            if (radio.is(':checked')) {
                selectedOptions.children().remove()
                let newOption = document.createElement('span');
                let selectedOptionName = radio.parent().find('label').html();
                $(newOption).addClass('name');
                $(newOption).html(selectedOptionName);
                $(newOption).attr('id', selectedLabelId)
                selectedOptions.removeClass('d-none');
                selectedOptions.append(newOption);
                selectText.addClass('d-none')

                let backgroundColor = radio.parent().parent().find('.color-indicator').css('background-color')
                $(newOption).css('background-color', backgroundColor)

                var publishReportBtn = $('.publish-report-btn .publish-report-link')
                publishReportBtn.removeClass('disabled')
                publishReportBtn.addClass('active')
            } else {
                $(`#${selectedLabelId}`).remove();
            }
        })
        if ($('.plan_reviews.edit, .plan_reviews.update').length !== 0) {
            dropdownContainer.find('.custom-radio').trigger('change')
        }
    }

    function parameterizeString(string) {
        return string.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '');
    }

    // save plan review form while typing starts here
    var planReviewForm = $('#plan-review-form')
    var submitBtn = planReviewForm.find('.submit-button')
    $(document).on('change', '#plan-review-form .plan-review-value', function (e) {
        submitBtn.click()
        if (e.target.name === 'plan_review[disciplines_reviewed][]') {
            $('.discipline-review-section').find('.select-dropdown').removeClass('d-none')
        }
    })
    // save plan review form while typing ends here

    // update previous review comments starts here
    $(document).on('change', '#plan-review-form .previous-comments textarea', function () {
        var value = $(this).val()
        var parentElement = $(this).parent().parent()
        var commentId = parentElement.attr('id')
        var name = $(this).attr('name')
        var planReviewId = parentElement.attr('plan_review_id')

        $.ajax({
            method: 'PATCH',
            url: '/review_comments/' + commentId,
            data: {
                value: value,
                name: name,
                plan_review_id: planReviewId
            }
        }).done(function (data) {
            if (data.error) {
                alert(data.error)
            }
        }).fail(function (data) {
            console.log(data)
        });
    })
    // update previous review comments starts here

    // resolve previous review comments starts here
    $(document).off('click', '#plan-review-form .previous-comments .resolve-comment').on('click', '#plan-review-form .previous-comments .resolve-comment', function () {
        if(confirm("Are you sure you want to resolve this comment?")) {
            var parentElement = $(this).parent().parent()
            var commentId = parentElement.attr('id')
            var planReviewId = parentElement.attr('plan_review_id')
            var sheetNumber = parentElement.find('.title').val()
            var comment = parentElement.find('.value').val()

            $.ajax({
                method: 'PATCH',
                url: '/review_comments/' + commentId + '/resolve',
                data: {
                    plan_review_id: planReviewId
                }
            }).done(function (data) {
                if (data.error) {
                    alert(data.error)
                } else {
                    var commentSection = parentElement.closest('.comment-section')
                    var commentRows = commentSection.find('.comment-row')
                    if (commentRows.length > 1) {
                        parentElement.delay(100).hide('slow', function () {
                            parentElement.remove();
                        })
                    } else {
                        commentSection.delay(100).hide('slow', function () {
                            commentSection.remove();
                        })
                    }
                    var resolveCommentSection = $('.resolved-comments')
                    var resolveCommentDetail = resolveCommentSection.find('.detail')
                    let clone = resolveCommentDetail.find('.row').first().clone(true)
                    clone.find('.title').text(sheetNumber)
                    clone.find('.value').text(comment)
                    clone.removeClass('d-none')
                    if (resolveCommentSection.css('display') === 'none') {
                        resolveCommentDetail.append(clone)
                        resolveCommentSection.delay(500).show('slow')
                    } else {
                        resolveCommentDetail.append(clone.hide().delay(500).show('slow'))
                    }
                }
            }).fail(function (data) {
                console.log(data)
            });
        }
    })
    // resolve previous review comments ends here

    // creating, updating and deleting review comments for current plan review starts here

    // cloning forms
    var reviewCommentSection = document.querySelector('.review-comments-section')
    if (reviewCommentSection) {
        $(document).off('click', '.insert-comment').on('click', '.insert-comment', function (e) {
            e.preventDefault()
            let clone = $('.cloningForm').last().clone(true)
            let textarea = $(clone).find("textarea")
            textarea.val('')
            $(clone).removeAttr('comment_id')
            let removeBtn = $(clone).find(".remove-comment")
            if (removeBtn.hasClass('d-none')) {
                removeBtn.removeClass('d-none')
            }
            if (clone.hasClass('d-none')) {
                clone.removeClass('d-none')
            }
            $(clone).addClass('newCommentForm')
            $(clone).find('.handle-drag').addClass('d-none')
            $(clone).appendTo('.wrapper')
            textAreaAdjust($(textarea))
        })

        $(document).on('click', '.remove-comment', function () {
            $(this).closest('.cloningForm').remove()
        })
    }

    $(document).on('change', '#plan-review-form .review-comments-section textarea', function () {
        var parentElement = $(this).closest('.cloningForm');
        var commentId = parentElement.attr('comment_id')
        var sheetNumber = parentElement.find('.sheet_number').val();
        var comment = parentElement.find('.comment').val();
        var planReviewId = parentElement.attr('plan_review_id')

        var reviewCommentSection = $('.review-comments-section')
        var projectId = reviewCommentSection.attr('project_id')
        var projectFolderId = reviewCommentSection.attr('project_folder_id')

        var data = {
            comment_id: commentId,
            sheet_number: sheetNumber,
            comment: comment,
            plan_review_id: planReviewId
        }

        if (!planReviewId) {
            $('.loading-spinner').show();
            $('body').css({
                background: "grey",
                opacity: 0.5,
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 1000,
                pointerEvents: "none"
            })
            $.ajax({
                method: 'POST',
                url: `/projects/${projectId}/project_folders/${projectFolderId}/plan_reviews`,
                contentType: "application/json",
                dataType: "json",
                data: JSON.stringify({
                    project_name: ""
                })
            }).done(function (res) {
                if (res.error) {
                    alert(res.error)
                } else {
                    planReviewId = res.plan_review_id
                    data.plan_review_id = planReviewId

                    makeChangesToForm(projectId, projectFolderId, planReviewId)

                    ajaxCall(data, parentElement, commentId)
                }
            }).fail(function (data) {
                console.log(data)
            });
            $('body').css({
                all: "unset"
            })
            $('.loading-spinner').hide();
        } else {
            ajaxCall(data, parentElement, commentId)
        }
    })

    function ajaxCall(data, parentElement, oldCommentId) {
        $.ajax({
            method: 'POST',
            url: '/review_comments/',
            data: data
        }).done(function (data) {
            if (data.error) {
                alert(data.error)
            } else {
                var newCommentId = data.comment_id
                if(parseInt(oldCommentId) !== parseInt(newCommentId)) {
                    parentElement.attr('comment_id', newCommentId)
                    parentElement.removeClass('newCommentForm')
                    parentElement.find('.handle-drag').removeClass('d-none')
                    $(parentElement).appendTo('#sortableList')
                }
            }
        }).fail(function (data) {
            console.log(data)
        });
    }

    function makeChangesToForm(projectId, projectFolderId, planReviewId) {
        //changes form action and browser url
        var planReviewForm = $('#plan-review-form')
        var url = `/projects/${projectId}/project_folders/${projectFolderId}/plan_reviews/${planReviewId}`
        planReviewForm.attr('action', url)
        planReviewForm.find('#plan_review_id').text(planReviewId)
        var appendableDiv = planReviewForm.find('.appendable-div')
        appendableDiv.empty()
        appendableDiv.append("<input type='hidden' name='_method' value='patch'>")
        history.replaceState('data', '', `${planReviewId}/edit`)

        //add action url to publish report button
        var publishReportLink = $('.publish-report-link')
        var linkUrl = `/projects/${projectId}/project_folders/${projectFolderId}/plan_reviews/${planReviewId}/publish`
        publishReportLink.attr('href', linkUrl)
        publishReportLink.attr('data-method', 'patch')

        //add plan_review_id to cloningForm
        var reviewCommentSection = $('.review-comments-section')
        reviewCommentSection.find('.cloningForm').attr('plan_review_id', planReviewId)
    }

    $(document).off('click', '#plan-review-form .review-comments-section .remove-comment').on('click', '#plan-review-form .review-comments-section .remove-comment', function () {
        var parentElement = $(this).closest('.cloningForm');
        var commentId = parentElement.attr('comment_id');
        var planReviewId = parentElement.attr('plan_review_id')

        if (commentId) {
            $.ajax({
                method: 'DELETE',
                url: '/review_comments/' + commentId,
                data: {
                    plan_review_id: planReviewId
                }
            }).done(function (data) {
                if (data.error) {
                    alert(data.error)
                }
            }).fail(function (data) {
                console.log(data)
            });
        }
    })
    // creating, updating and deleting review comments for current plan review ends here

    // drag and drop plan review comments starts here
    Sortable.create(sortableList, {
        handle: '.handle-drag',
        onEnd: function (evt) {
            handleSortEvent(evt)
        },
        animation: 150,
        dragClass: 'drag-item',
        ghostClass: 'drag-background',
        draggable: ".cloningForm"
    });

    function handleSortEvent(evt) {
        // Get the new order of the items
        const orderData = getOrderData();

        let itemEl = evt.item;  // dragged HTMLElement
        let commentId = $(itemEl).attr('comment_id');
        let planReviewId = $(itemEl).attr('plan_review_id');
        let token = $("meta[name='csrf-token']").attr('content');

        if (commentId) {
            $.ajax({
                method: 'PATCH',
                url: '/review_comments/' + commentId + '/reorder',
                data: {
                    plan_review_id: planReviewId,
                    order_data: orderData,
                    authenticity_token: token
                }
            }).done(function (data) {
                if (data.error) {
                    alert(data.error)
                }
            }).fail(function (data) {
                console.log(data)
            });
        }
    }

    // Function to get the new order data
    function getOrderData() {
        const items = document.querySelectorAll('#sortableList .cloningForm');
        return Array.from(items).map((item, index) => ({
            comment_id: item.getAttribute('comment_id'),
            comment_order: index + 1
        }));
    }
    // drag and drop plan review comments ends here
});