import consumer from "./consumer"

document.addEventListener('turbolinks:load', () => {
  const screenshotForm = document.getElementById('screenshotForm')
  if (!screenshotForm) {
    return
  }

  const roomId = screenshotForm.getAttribute('data-inspection-id')

  consumer.subscriptions.create({ channel: "InspectionChatChannel", room: `inspection_screenshot_${roomId}` }, {
    async received(data) {
      let videoElement = document.querySelector('#localVideo video');
      if (!videoElement) {
        console.error('No video element found!');
        return; // Stop execution if no video element is found
      }

      let canvas = document.createElement('canvas');
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      let context = canvas.getContext('2d');
      context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

      const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg'));
      const formData = new FormData();
      formData.append('screenshot', blob, 'screenshot.jpg');

      fetch(data.url, {
        method: 'POST',
        body: formData,
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        }
      })
        .then(response => response.json())
        .then(data => {
          console.log('Screenshot uploaded successfully:', data);
        })
        .catch(error => {
          console.error('Error uploading screenshot:', error);
        });
    },
  })
});
