document.addEventListener("DOMContentLoaded", function () {
  let timeLeft = 10; // 2 minutes in seconds
  let timerInterval;
  const countdownTimer = document.getElementById("countdownTimer");
  const inviteContractorButton = document.getElementById("inviteContractorButton");

  if(!countdownTimer || !countdownTimer) {
    return
  }

  function updateTimer() {
    let minutes = Math.floor(timeLeft / 60);
    let seconds = timeLeft % 60;
    countdownTimer.textContent = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    timeLeft--;

    if (timeLeft < 0) {
      clearInterval(timerInterval);
      inviteContractorButton.style.display = "inline";
      $('#countdownContainer')?.addClass('d-none');
    }
  }

  function startTimer() {
    timeLeft = 120;
    countdownTimer.textContent = "2:00";
    $('#countdownContainer')?.removeClass('d-none');
    inviteContractorButton.style.display = "none";
    timerInterval = setInterval(updateTimer, 1000);
  }

  inviteContractorButton.addEventListener("click", function () {
    clearInterval(timerInterval);
    startTimer();

    const endpoint = inviteContractorButton.getAttribute("data-endpoint");

    fetch(endpoint)
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(data => {
        console.log("GET request successful:", data);
      })
      .catch(error => {
        console.error("There was a problem with the GET request:", error);
      });
  });

  startTimer();
});
