document.addEventListener('turbolinks:load', function () {
  $('body').on('click', '.sort-button', function () {
    $('.filterLoader').addClass('active');
    var btn = $(this);
    var sortField = $('#filterrific_sorted_by');
    if (btn.hasClass('desc')) {
      btn.removeClass('desc');
      btn.addClass('asc');
      sortField.val(`${btn.data('sort')}_asc`);
    } else {
      btn.removeClass('asc');
      btn.addClass('desc');
      sortField.val(`${btn.data('sort')}_desc`);
    }
    $(this).toggleClass('');
    submitFilter('#filterrific_filter');
  });
});