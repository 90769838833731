document.addEventListener('turbolinks:load', function () {
    if ($('.projects.new, .projects.create, .projects.edit, .projects.update').length === 0) {
        return false
    }

    // do not want to trigger this js if we are working on permit applications form
    // even though it is permit applications form, it is served under projects/edit so this js gets triggered
    if($('.permit_applications-form-section').length !== 0) {
        return false
    }

    window.selectedProjectType = [];

    if ($('.projects.edit, .projects.update').length !== 0) {
        processSelectionChanges();
    }

    $(document).on('change', "#v-service-requested input[type='checkbox']", function () {
        resetChanges();
        processSelectionChanges();
    })

    function processSelectionChanges() {
        var selected = [];
        if (isBothSelected()) {
            selected.push("plan_review", "inspection");
        } else if (isPlanReviewSelected()) {
            selected.push("plan_review")
        } else if (isInspectionSelected()) {
            selected.push("inspection")
        } else {
            selected = []
        }
        processSelection(selected);
        toggleNextStepButton();
    }

    function processSelection(selected) {
        if (isSameArray(selected, ["plan_review", "inspection"])) {
            selectBothService()
        } else if (selected.length === 1 && selected[0] === "plan_review") {
            selectPlanReviewOnly();
        } else if (selected.length === 1 && selected[0] === "inspection") {
            selectInspectionOnly()
        } else {
            deSelectBothService();
        }
    }

    function isSameArray(array1, array2) {
        return (array1.length === array2.length) && array1.every(function (element, index) {
            return element === array2[index];
        })
    }

    function selectBothService() {
        selectService("plan_review");
        selectService("inspection");
        showAllTabs()
    }

    function selectPlanReviewOnly() {
        selectService("plan_review");
        showAllTabs()
    }

    function selectInspectionOnly() {
        selectService("inspection");
        $("#v-project-overview-tab").removeClass("d-none");
        $("#v-project-overview .create-project-btn").removeClass("d-none");
        $("#v-project-overview .next-step-btn").addClass("d-none");
    }

    function deSelectBothService() {
        deSelectService("plan_review");
        deSelectService("inspection");
        $("#v-project-overview-tab, #v-project-details-tab, #v-permit-information-tab").addClass("d-none")
    }

    function showAllTabs() {
        $("#v-project-overview-tab, #v-project-details-tab, #v-permit-information-tab").removeClass("d-none")
        $("#v-project-overview .create-project-btn").addClass("d-none");
        $("#v-project-overview .next-step-btn").removeClass("d-none");
    }

    function resetChanges() {
        deSelectBothService();
    }

    function isPlanReviewSelected() {
        return $("#plan_review_service").prop("checked");
    }

    function isInspectionSelected() {
        return $("#inspection_service").prop("checked");
    }

    function isBothSelected() {
        return isPlanReviewSelected() && isInspectionSelected();
    }

    function selectService(service) {
        $("#" + service).addClass("selected");
        window.selectedProjectType.push(service);
    }

    function deSelectService(service) {
        $("#" + service).removeClass("selected");

        // remove de-selected service from the window object
        const index = window.selectedProjectType.indexOf(service);
        if (index > -1) {
            window.selectedProjectType.splice(index, 1);
        }
    }

    function toggleNextStepButton() {
        if ($("#v-service-requested input:checkbox:checked").length > 0) {
            $('#v-service-requested .next-step-btn').attr('disabled', false);
        } else {
            $('#v-service-requested .next-step-btn').attr('disabled', true);
        }
    }
});