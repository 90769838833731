window.formatTeamResult = (team) => {
    if (team.loading) {
        return team.text;
    }

    var avatar_url = team.avatar_url
    var full_name = team.full_name
    var initial = team.initial

    var display_image_element = []
    if (avatar_url) {
        display_image_element = "<div class='select2-result-team-avatar'><img src='" + avatar_url + "' /></div>"
    } else {
        display_image_element = "<div class='select2-result-team-initial'>" + initial + "</div>"
    }

    var $container = $(
        "<div class='select2-result-team clearfix'>" + display_image_element +
        "<div class='select2-result-team-name'>" + full_name + "</div>" +
        "</div>"
    );
    return $container;
}