document.addEventListener('turbolinks:load', function () {
    if ($('.lead_stages.index').length === 0) {
        return false
    }

    let formCard = $('.form-card')
    formCard.find(".checkbox-options input[type='checkbox']").each(function () {
        toggleSubOptions($(this))
    })

    $(document).on('change', ".checkbox-options input[type='checkbox']", function () {
        if ($(this).hasClass('has_sub_options')) {
            let value = $(this).val();
            let subOptions = formCard.find(".sub-options-" + value)
            if ($(this).prop('checked')) {
                subOptions.removeClass('d-none')
            } else {
                subOptions.addClass('d-none')
            }
        }
    })

    function toggleSubOptions(checkbox_option) {
        if (checkbox_option.hasClass('has_sub_options')) {
            let value = checkbox_option.val();
            let subOptions = formCard.find(".sub-options-" + value)
            if (checkbox_option.prop('checked')) {
                subOptions.removeClass('d-none')
            } else {
                subOptions.addClass('d-none')
            }
        }
    }
});