document.addEventListener('turbolinks:load', function () {
    if ($('.project_folders.show, .projects.hud_project, .projects.new, .projects.create, .projects.edit, .projects.update, .projects.show, .projects.create_hud_project, .projects.edit_hud_project, .projects.update_hud_project').length === 0) {
        return false
    }

    // do not want to trigger this js if we are working on permit applications form
    // even though it is permit applications form, it is served under projects/edit so this js gets triggered
    if($('.permit_applications-form-section').length !== 0) {
        return false
    }

    let completeFinalInspectionModal = document.querySelector("#completeFinalInspectionModal");
    let projectOverviewTab = document.querySelector("#projectOverviewTab");
    let addPermitFeeModal = document.querySelector("#addPermitFeeModal");

    if (completeFinalInspectionModal) {
        formSelection(completeFinalInspectionModal);
    }

    if (projectOverviewTab) {
        formSelection(projectOverviewTab, true);
        addRemoveBtnEvent(projectOverviewTab)
    }

    if (addPermitFeeModal) {
        formSelection(addPermitFeeModal);
    }

    // for edit case when multiple email fields are already present
    function addRemoveBtnEvent(projectOverviewTab) {
        // for contractor email address
        let contractorEmailAddressSection = projectOverviewTab.querySelector('.contractor-email-address-section')
        contractorEmailAddressSection.querySelectorAll('.removeFormBtn').forEach((btn) => {
            removeAddedForm(btn, true)
        })

        // for architect email address
        let architectEmailAddressSection = projectOverviewTab.querySelector('.architect-email-address-section')
        architectEmailAddressSection.querySelectorAll('.removeFormBtn').forEach((btn) => {
            removeAddedForm(btn, true)
        })
    }

    function formSelection(modal, array = false) {
        modal.querySelectorAll('.wrapper').forEach(function(wrapper){
            let cloningForm = wrapper.querySelector('.cloningForm');
            let addMoreBtn = wrapper.parentNode.querySelector('.addMoreBtn');
            triggerButton(wrapper, cloningForm, addMoreBtn, array);
        })
    }

    function triggerButton(wrapper, cloningForm, addMoreBtn, array = false) {
        addMoreBtn.addEventListener('click', function (e) {
            e.preventDefault();

            let newForm;

            newForm = cloningForm.cloneNode(true);

            // changing name attribute for cloned text field to make it easier to store data
            // on the database
            changeInNameAttribute(newForm, array);

            // show remove button to remove cloned rows
            showRemoveButton(newForm, array);

            wrapper.appendChild(newForm);
        });
    }

    function changeInNameAttribute(newForm, array = false) {
        let newCount = document.querySelectorAll('.cloningForm').length + 1;
        newForm.querySelectorAll("input").forEach(function (field) {
            // making all cloned text field empty
            field.removeAttribute('required');
            field.value = "";

            // if field data type is array, do not change the field name while cloning
            if(!array) {
                let field_name = field.name.replace('[1]', '').split('[');
                let len = field_name.length - 1;
                field_name.splice(len, 0, '' + newCount + ']');
                field.name = field_name.join('[');
            }
        });
    }

    function showRemoveButton(newForm, array = false) {
        let action = newForm.querySelector('.action');
        let removeBtn = action.querySelector('.removeFormBtn')
        removeBtn.classList.remove('d-none')

        removeAddedForm(removeBtn, array);
    }

    function removeAddedForm(removeBtn, array = false) {
        removeBtn.addEventListener('click', function (e) {
            e.preventDefault();
            removeBtn.parentElement.parentElement.remove();

            // to change the name attribute again after removing one row
            newFormChanges(array)
        })
    }

    function newFormChanges(array = false) {
        document.querySelectorAll('.cloningForm').forEach(function (form, count) {
            form.querySelectorAll('input').forEach(function (field) {
                // if field data type is array, do not change the field name
                if(!array){
                    let field_name = field.name.split('[');
                    field_name[1] = count + 1 + ']';
                    field.name = field_name.join('[');
                }
            })
        })
    }
});